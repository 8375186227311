.inner-div {
    margin-top: 5px;
    margin-bottom: -10px;
    overflow: scroll !important;
}

.no_background {
    background: none !important;
    overflow: scroll !important;
}

.right_alignment {
    float: right !important;
    margin-right: 2px !important;
    padding: 5px !important;
    color: white !important;
}

.scrollable {
    overflow-x: scroll !important;
}
.truncate_text {
    white-space: nowrap;
    /*keep text on one line */
    overflow: hidden;
    /*prevent text from being shown outside the border */
    text-overflow: ellipsis;
    /*cut off text with an ellipsis*/
    width: 70px;
}

.no_bottom_margin {
    margin: 0 0 5px !important;
}

.no_padding {
    padding: 13px px 0 !important;
}

.no_widget_margin {
    margin: 0 0 0px !important;
}

.information_widdget {
    border-top: none !important;
}

#background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
}

.progress-bar-title {
    position: absolute;
    text-align: center;
    line-height: 20px;
    /* line-height should be equal to bar height */
    overflow: hidden;
    right: 0;
    left: 0;
    font-weight: bold;
    top: 0;
}

.progress-bar {
    font-size: 0 !important;
}

.modal-dialog {
    width: 70vw !important;
}

@media only screen and (max-width: 600px) {
    .modal-dialog {
        width: 100vw !important;
    }
}

.grouped_kpi_fixed_height {
    max-height: 300px;
}

.graph_fixed_height {
    max-height: 300px;
}

.dyanmic {
    display: block;
    float: left;
    min-height: 100%;
    height: auto !important;
    height: 100%;
}

.relative-height{
    height: 200px !important;
    background-color: brown;
}


.autcomplete{
	width: 100%;
	border: 1px solid grey;


}

.autcomplete input{
	width: 100%;
}

.autcomplete ul{
	list-style-type: none;
	text-align: left;
}

.no_width {
	width: auto !important;
	margin-top: 20px;	
}

.no_width :hover{
	background-color: #3276b1;
}
