.autcomplete{
	width: 100%;
	border: 1px solid grey;


}

.autcomplete input{
	width: 100%;
}

.autcomplete ul{
	list-style-type: none;
	text-align: left;
}

.no_width {
	width: auto !important;
	margin-top: 20px;	
}

.no_width :hover{
	background-color: #3276b1;
}